const handleAccordions = () => {
  const accordions = document.querySelectorAll(".Accordion");
  if (accordions) {
    accordions.forEach((accordion) => {
      const accordionButton = accordion.childNodes[0];
      accordionButton.addEventListener("click", () => {
        accordion.classList.toggle("Accordion--active");
        const isExpanded =
          accordionButton.getAttribute("aria-expanded") === "true";
        isExpanded
          ? accordionButton.setAttribute("aria-expanded", false)
          : accordionButton.setAttribute("aria-expanded", true);

        const accordionPanel = accordion.childNodes[1];

        const isPanelHidden =
          accordionPanel.getAttribute("aria-hidden") === "true";
        isPanelHidden
          ? accordionPanel.setAttribute("aria-hidden", false)
          : accordionPanel.setAttribute("aria-hidden", true);
      });
    });
  }
};

export default handleAccordions;
