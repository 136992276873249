// Import styles
import './main.scss';

// Import fonts used. They go through a file loader that
// put them into dist/fonts when compiled. See how this is
// handeled in line 62 in webpack.common.js
// Import fonts
import CabinetGroteskMediumWoff from './fonts/CabinetGrotesk-Medium.woff';
import CabinetGroteskMediumWoff2 from './fonts/CabinetGrotesk-Medium.woff2';
import CabinetGroteskMediumTtf from './fonts/CabinetGrotesk-Medium.ttf';
import CabinetGroteskBoldWoff from './fonts/CabinetGrotesk-Bold.woff';
import CabinetGroteskBoldWoff2 from './fonts/CabinetGrotesk-Bold.woff2';
import CabinetGroteskBoldTtf from './fonts/CabinetGrotesk-Bold.ttf';
import CabinetGroteskBlackWoff from './fonts/CabinetGrotesk-Black.woff';
import CabinetGroteskBlackWoff2 from './fonts/CabinetGrotesk-Black.woff2';
import CabinetGroteskBlackTtf from './fonts/CabinetGrotesk-Black.ttf';

// Components
import InViewport from './components/InViewport';
import Accordions from './components/Accordion';
import BaseExpandable from './components/BaseExpandable';
import Dropdown from './components/Dropdown';

//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

//
// Application
//
const DQNorway = {
  // Options / globals
  navTrigger: null,
  body: document.body,
  header: document.getElementById('TheHeader'),
  navOpen: false,
  navTrigger: document.getElementById('NavTrigger'),
  nav: document.getElementById('TheMainNav'),

  init() {
    this.viewportObserver = new InViewport();

    // Observe elements in viewport
    [...document.querySelectorAll('[data-in-viewport]')].forEach(el => this.viewportObserver.observe(el));

    // Expandables
    [...document.querySelectorAll('[data-expandable-trigger]')].forEach(el => new BaseExpandable(el));

    // Accordions
    Accordions();

    // Nav trigger
    this.navTriggerClickListener = this.toggleNavigation.bind(this);
    this.navTrigger.addEventListener('click', this.navTriggerClickListener);

    // Nav scroll toggle
    this.navTrigger.addEventListener('click', () => this.body.classList.toggle('has-open-nav'));

    // Dropdown
    const dropdown = document.querySelector('.Dropdown');
    if(dropdown) {
      new Dropdown();
    }

    // On browser resize
    // window.addEventListener('resize', () => this.onResize());
  },

  toggleNavigation() {
    this.navOpen = !this.navOpen;
    if(this.navOpen) {
      this.navTrigger.setAttribute('aria-expanded', 'true');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.closeLabel);
      this.nav.classList.add('TheMainNav--open');
      const firstButton = this.nav.querySelector('button');
      if(firstButton) { firstButton.focus(); }
    } else {
      this.navTrigger.setAttribute('aria-expanded', 'false');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.openLabel);
      this.nav.classList.remove('TheMainNav--open');
    }
  },

  initOnLoad() {
  }
};

document.addEventListener('DOMContentLoaded', () => DQNorway.init());
window.addEventListener('load', () => DQNorway.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}


// TODO: Set up this properly
const tabs = document.querySelectorAll(".my-tabs .tabs li");
const sections = document.querySelectorAll(".my-tabs .tab-content");

tabs.forEach(tab => {
  tab.addEventListener("click", e => {
    e.preventDefault();
    removeActiveTab();
    addActiveTab(tab);
  });
})

const removeActiveTab = () => {
  tabs.forEach(tab => {
    tab.classList.remove("is-active");
  });
  sections.forEach(section => {
    section.classList.remove("is-active");
  });
}

const addActiveTab = tab => {
  tab.classList.add("is-active");
  const href = tab.querySelector("a").getAttribute("href");
  const matchingSection = document.querySelector(href);
  matchingSection.classList.add("is-active");
}

// Agenda collapsible
const questions = document.querySelectorAll('.collapsible')
questions.forEach(question => {
  const opener = question.querySelector('.read-more')
  opener.addEventListener('click', () => {
    // close all the other sections
    [...questions].filter(q => q !== question).forEach(q => q.classList.remove('is-open'))

    // then open the current section
    question.classList.toggle('is-open')
  })
})
