import BaseComponent from './BaseComponent';

/**
 * Base Expandable is used to add toggle functionality
 * for elements
 */
export default class BaseExpandable {
  /**
   * Constructor
   *
   * @param HTMLElement $root - Element used for triggering the expandable,
   * e.g. button above section to be opened.
   *
   */
  constructor(root) {
    this.root = root;
    this.target_ = root.nextElementSibling;
    this.registerEventHandlers();
  }

  registerEventHandlers() {
    this.onClickHandler = this.onClick.bind(this);
    this.root.addEventListener('click', this.onClickHandler);
  }

  onClick() {
    let expanded = this.root.getAttribute('aria-expanded') === 'true' || false;
    this.root.setAttribute('aria-expanded', !expanded);
    this.root.setAttribute('aria-pressed', !expanded);
    this.target_.setAttribute('aria-hidden', expanded);
  }
}
